.container {
  padding: 24px;
  height: 100%;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }

  .content {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    .iconWrapper {
      padding: 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
    }

    .icon {
      font-size: 1.2rem;
    }

    .textContent {
      flex: 1;

      .label {
        font-weight: 500;
      }

      .value {
        word-break: break-word;
        font-weight: 600;
      }
    }
  }
}
